import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Table from '../components/Table'

export default function ipReviewTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const documentName = filePathParts[2]
  const patentTableHeaders = [
    'Application / Patent Number (99/999,999)',
    'Patent Status',
    'Status Date',
    'Assignee',
    'Description',
    'Total Claims',
    'What Prevents Infringement?',
    'Claims Needing Attorney Review',
  ]
  return (
    <Layout title={documentName} filePath={filePath}>
      <Table table={frontmatter.patentTable} headers={patentTableHeaders} />
      {frontmatter.patentClaimReviews.map((patent) => (
        <div>
          <h2 className="title is-2">Patent Number: {patent.patentNumber}</h2>
          <Table table={patent.claimsTable} />
        </div>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryIPReviewTemplate($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        templateName
        patentTable {
          patentNumber
          filingDate
          patentStatus
          statusDate
          assignee
          description
          totalClaims
          whatPreventsInfringement
          claimsNeedingAttorneyReview
        }
        patentClaimReviews {
          patentNumber
          claimsTable {
            claimNumber
            claimElement
            isClaimElementInfringed
            claimConclusion
          }
        }
      }
    }
  }
`
